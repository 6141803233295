import './App.css';
import React from 'react';
import CreateForm from './component/CreateForm';
import { BrowserRouter as Router, Routes ,Route } from 'react-router-dom';
import Operator from './component/Operator';
import QRmaker from './component/QRmaker';
import QRReader from './component/QRReader';
import 'typeface-pt-sans';
import QRInput from './component/QRInput';
import NavContainer from './component/NavContainer';
import EditForm from './component/EditForm';

function App() {
  return (
    <Router>
      <Routes>
          <>
            <Route path='/' element={<Operator />}/>
            <Route path='input' element={<QRInput/>}/>
            <Route path='create' element={<CreateForm/>} />
            <Route path='database/qrmaker/:id' element={<QRmaker/>} />
            <Route path='qrreader' element={<QRReader/>} />
            <Route path='database' element={<NavContainer/>} />
            <Route path='database/edit/:id' element={<EditForm/>}/>
          </>
      </Routes>
    </Router>
  );
}

export default App;
