import React from 'react';
import { useState } from 'react';
import Footer from './Footer';
import Navbar from './Navbar/index';
import SidebarNav from './Sidebar/index';
import Database from './Database';

const NavContainer = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () =>{
        setIsOpen(!isOpen)
    }

    return (
    <>
        <SidebarNav isOpen={isOpen} toggle={toggle}/>
        <Navbar toggle={toggle}/>
        <Database />
        <Footer />
    </>
    )
};

export default NavContainer;
