import React, { useState, useEffect } from "react";
import "./EditForm.css"
import axios from "axios";
import { useParams, Link, useNavigate} from "react-router-dom";

function EditForm(props) {
    const [nomor, setNomor] = useState('')
    const [clientId, setClientId] = useState('')
    const [name, setNama] = useState('')
    const [seat, setKursi] = useState('')
    const [guest, setJumlah] = useState('')
    const [loading, setLoading] = useState(false)
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      getTamubyId();
    }, []);
  
    const updateTamu = async (e) => {
      setLoading(true)
      try {
        await axios.patch(`https://api.rootinvitation.com/tamu/${id}`, {
            "clientId": clientId,
            "name": name,
            "jumlah": guest,
            "seat": seat
        });
        navigate("/database")
    } catch (error) {
        console.log(error);
      }
      setLoading(false)
    };
  
    const getTamubyId = async () => {
      const response = await axios.get(`https://api.rootinvitation.com/tamu/${id}`);
      setLoading(true)
      setNomor(response.data.id)
      setClientId(response.data.clientId)
      setNama(response.data.name);
      setKursi(response.data.seat);
      setJumlah(response.data.jumlah);
      setLoading(false)
    };
    return (
        <div className="loginForm">
            <div className="form-container">
                <div className="form-inner">
                    <Link to='/database' style={{ textDecoration: 'none' }}>
                        <div className='back-home'>
                            <i class="fas fa-long-arrow-alt-left" />
                            &ensp;Back to Database
                        </div>
                    </Link>
                    <h2>Edit User</h2>
                    <div className="form-group">
                        <label htmlFor="nomer">Nomor: {nomor}</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="nomer">Client: </label>
                        <input type="text" name="nomer" id="nomer" onChange={e => setClientId(e.target.value)} value={clientId} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="nama">Nama: </label>
                        <input type="text" name="name" id="name" onChange={e => setNama(e.target.value)} value={name} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="seat">Seat: </label>
                        <input type="text" name="seat" id="seat" onChange={e => setKursi(e.target.value)} value={seat}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="guest">Guest: </label>
                        <input type="text" name="guest" id="guest" onChange={e => setJumlah(e.target.value)} value={guest}/>
                    </div>
                    <input type="submit" value={loading ? '...' : "Update"} disabled={loading} onClick={updateTamu}/>
                </div>
            </div>
        </div>
    )
}

export default EditForm
