import React, { useEffect, useState } from 'react';
import './Database.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { getClient } from '../utils/common';

function Database(props) {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    let response;
    if (getClient() === '*') {
      response = await axios.get('https://api.rootinvitation.com/tamu/');
    } else {
      response = await axios.get(`https://api.rootinvitation.com/client/${getClient()}`);
    }
    setUsers(response.data);
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(`https://api.rootinvitation.com/tamu/${id}`);
      getUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); 
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="loginForm">
      <div className="form-container">
        <div className="formdash-inner">
          <h2>Data Tamu</h2>
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
          />

          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Client</th>
                <th>Name</th>
                <th>Guest</th>
                <th>Seat</th>
                <th>Actions</th>
                <th>Absence</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((user, index) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.clientId}</td>
                  <td>{user.name}</td>
                  <td>{user.jumlah}</td>
                  <td>{user.seat}</td>
                  <td>
                    <Link to={`edit/${user.id}`} className="cute-button">
                      Edit
                    </Link>
                    <button
                      onClick={() => deleteUser(user.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                    <Link to={`qrmaker/${user.id}`} className="cute-button">
                      View QR
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }).map(
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={currentPage === index + 1 ? 'active' : ''}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Database;
