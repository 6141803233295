import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SidebarWrapper,
    SideBtnWrap,
    SidebarRoute,
} from './SidebarElements'

const SidebarNav = ({ isOpen, toggle}) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SideBtnWrap>
                    <SidebarRoute to="/create">Create QR Code</SidebarRoute>
                </SideBtnWrap>
                <SideBtnWrap>
                    <SidebarRoute to="/input">QR Reader</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default SidebarNav
