import React, {useState} from 'react'
import "./CreateForm.css"
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";

function CreateForm(props) {
    const [clientId, setClientId] = useState('')
    const [name, setNama] = useState('')
    const [seat, setKursi] = useState('')
    const [guest, setJumlah] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const SubmitForm = async (e) => {
        setLoading(true)
        e.preventDefault();
        try {
          await axios.post("https://api.rootinvitation.com/tamu", {
            "clientId": clientId,
            "name": name,
            "jumlah": guest,
            "seat": seat
          });
          navigate("/database");
        } catch (error) {
          console.log(error);
        }
        setLoading(false)
      };

    return (
        <div className="loginForm">
            <div className="form-container">
                <div className="form-inner">
                    <Link to='/database' style={{ textDecoration: 'none' }}>
                        <div className='back-home'>
                            <i class="fas fa-long-arrow-alt-left" />
                            &ensp;Back to Dashboard
                        </div>
                    </Link>
                    <h2>Create Tamu</h2>
                    <div className="form-group">
                        <label htmlFor="nomer">Nama Client: </label>
                        <input type="text" name="nomer" id="nomer" onChange={e => setClientId(e.target.value)} value={clientId} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="nama">Nama: </label>
                        <input type="text" name="name" id="name" onChange={e => setNama(e.target.value)} value={name} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="seat">Seat: </label>
                        <input type="text" name="seat" id="seat" onChange={e => setKursi(e.target.value)} value={seat}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="guest">Guest: </label>
                        <input type="text" name="guest" id="guest" onChange={e => setJumlah(e.target.value)} value={guest}/>
                    </div>
                    <Link to='/qrmaker'>
                        <input type="submit" value={loading ? '...' : "Create"} disabled={loading} onClick={SubmitForm}/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CreateForm
