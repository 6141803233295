import React, {useEffect, useState} from 'react'
import "./QRmaker.css"
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import QRCode from "qrcode.react";


function QRmaker(props) {
    const [loading, setLoading] = useState(false)
    const [nomor, setNomor] = useState('')
    const [clientId, setClientId] = useState('')
    const [name, setNama] = useState('')
    const [seat, setKursi] = useState('')
    const [guest, setJumlah] = useState('')
    const { id } = useParams();

    
    useEffect(() => {
        getTamubyId();
      }, []);

    const downloadQRCode = () => {
        setLoading(true)
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${name}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setLoading(false)
    };
    const getTamubyId = async () => {
        const response = await axios.get(`https://api.rootinvitation.com/tamu/${id}`);
        setLoading(true)
        setNomor(response.data.id)
        setClientId(response.data.clientId)
        setNama(response.data.name);
        setKursi(response.data.seat);
        setJumlah(response.data.jumlah);
        setLoading(false)
    };

    return (
        <div className="loginForm">
            <div className="form-container">
                <div className="form-inner">
                    <Link to='/database' style={{ textDecoration: 'none' }}>
                        <div className='back-home'>
                            <i class="fas fa-long-arrow-alt-left" />
                            &ensp;Back to Dashboard
                        </div>
                    </Link>
                    <h2>QR Code</h2>
                    <div className="qr-code">
                        <QRCode
                            id="qr-gen"
                            level="Q"
                            size={220}
                            value={[nomor, clientId, name, seat, guest]}
                            includeMargin={true}
                        />
                    </div>
                    <input type="submit" value={loading ? '...' : "Save Image"} disabled={loading} onClick={downloadQRCode}/>
                </div>
            </div>
        </div>
    )
}

export default QRmaker
