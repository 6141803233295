import React, {useEffect, useState} from 'react'
import "./QRInput.css"
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import { getOperator } from '../utils/common';

function QRInput(props) {
    const [angpao, setAngpao] = useState('')
    const [give, setGive] = useState('')
    const [nama, setNama] = useState('')
    const [kursi, setKursi] = useState('')
    const [jumlah, setJumlah] = useState('')
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [timestamp, setTimestamp] = useState(new Date().toLocaleString());

    useEffect(() => {
      // Function to update the timestamp
      const updateTimestamp = () => {
        setTimestamp(new Date().toLocaleString());
      };
  
      // Set up an interval to update the timestamp every second (1000 milliseconds)
      const intervalId = setInterval(updateTimestamp, 1000);
  
      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }, []);

    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const SubmitQR = () => {
        setLoading(true)
        var data = new FormData();
        data.append('Time', timestamp)
        data.append('Nomor', angpao);
        data.append('Name', nama);
        data.append('Seat', kursi);
        data.append('Guest', jumlah);
        data.append('Give', checked);
        data.append('Jumlah', give);
        data.append('Operator', getOperator())
        fetch(
          "https://script.google.com/macros/s/AKfycbylzdK9McIeXSkJuMgP4dDA6QJ3Lj4NxDRzQjQfaoXwDTGHDMT1Zb6TeUyNm45E_rnCGg/exec",
          {
            method: "POST",
            body: data
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });
          const printContents = document.getElementById("printableDiv").innerHTML;
          const originalContents = document.body.innerHTML;
          document.body.innerHTML = printContents;
          window.print();
          document.body.innerHTML = originalContents;
          document.querySelector("input[type='submit']").addEventListener("click", SubmitQR);
          setLoading(false)
    }

    return (
        <div className="loginForm">
            <div className="form-container">
                <div className="form-inner" >
                    <Link to='/database' style={{ textDecoration: 'none' }}>
                        <div className='back-home'>
                            <i class="fas fa-long-arrow-alt-left" />
                            &ensp;Back to Dashboard
                        </div>
                    </Link>
                    <h2>Input Data</h2>
                    <div id="printableDiv">
                        <div className="form-group">
                            <label htmlFor="angpao">Nomor Angpao: </label>
                            <input type="text" name="angpao" id="angpao" onChange={e => setAngpao(e.target.value)} value={angpao}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="nama">Nama: </label>
                            <input type="text" name="nama" id="nama" onChange={e => setNama(e.target.value)} value={nama}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="kursi">Seat: </label>
                            <input type="text" name="kursi" id="kursi" onChange={e => setKursi(e.target.value)} value={kursi}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="jumlah">Guest: </label>
                            <input type="text" name="jumlah" id="jumlah" onChange={e => setJumlah(e.target.value)} value={jumlah}/>
                        </div>
                        <div className="form-group">
                                <label htmlFor="checked">Give: </label>
                                {checked ? 
                                <input type="text" name="jumlah" id="jumlah" onChange={e => setGive(e.target.value)} value={give}/>
                                :
                                <></>
                                }
                                <Switch
                                  onChange={handleChange}
                                  checked={checked}
                                  className="react-switch"
                                />
                            </div>
                        </div>
                        <input type="submit" value={loading ? '...' : "Input & Print"} disabled={loading} onClick={SubmitQR}/>
                        <Link to='/qrreader'>
                            <input type="submit" value={loading ? '...' : "Scan QR Code"} disabled={loading}/>
                        </Link>
                </div>
            </div>
        </div>
    )
}

export default QRInput
