import "./Operator.css"
import React from 'react';
import { Link } from 'react-router-dom';
import { setClient, setOperator } from "../utils/common";

function Operator(props) {
    return (
        <div className="loginForm">
            <div className="form-container">
                <div className="form-inner">
                    <h2>Input Operator</h2>
                        <div id="printableDiv">
                            <div className="form-group">
                                <label htmlFor="nama">Operator: </label>
                                <input type="text" name="name" id="name" onChange={e => setOperator(e.target.value)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="nama">Client: </label>
                                <input type="text" name="client" id="client" onChange={e => setClient(e.target.value)}/>
                            </div>
                        </div>
                        <Link to="/database">
                            <input type="submit" value="Start Working"/>
                        </Link>
                </div>
            </div>
        </div>
    )
}

export default Operator
