export const getName = () => {
    const userStr = sessionStorage.getItem("Name")
    if(userStr) return JSON.parse(userStr)
    else return null;
}

export const getSeat = () => {
    const adminStr = sessionStorage.getItem("Seat")
    if(adminStr) return JSON.parse(adminStr)
    else return null;
}

export const getGuest = () => {
    const adminStr = sessionStorage.getItem("Guest")
    if(adminStr) return JSON.parse(adminStr)
    else return null;
}

export const getAngpao = () => {
    const adminStr = sessionStorage.getItem("Angpao")
    if(adminStr) return JSON.parse(adminStr)
    else return null;
}

export const getOperator = () => {
    const userStr = sessionStorage.getItem("Operator")
    if(userStr) return JSON.parse(userStr)
    else return null;
}

export const getClient = () => {
    const userStr = sessionStorage.getItem("Client")
    if(userStr) return JSON.parse(userStr)
    else return null;
}

export const setAngpao = (token) => {
    sessionStorage.setItem("Angpao", JSON.stringify(token));
}

export const setName = (token) => {
    sessionStorage.setItem("Name", JSON.stringify(token));
}

export const setSeat = (token) => {
    sessionStorage.setItem("Seat", JSON.stringify(token));
}

export const setGuest = (token) => {
    sessionStorage.setItem("Guest", JSON.stringify(token));
}

export const setOperator = (token) => {
    sessionStorage.setItem("Operator", JSON.stringify(token));
}

export const setClient = (token) => {
    sessionStorage.setItem("Client", JSON.stringify(token));
}
export const removeAngpao = () => {
    sessionStorage.removeItem("Angpao")
}

export const removeName = () => {
    sessionStorage.removeItem("Name")
}

export const removeSeat = () => {
    sessionStorage.removeItem("Seat")
}

export const removeGuest = () => {
    sessionStorage.removeItem("Guest")
}

export const removeOperator = () => {
    sessionStorage.removeItem("Operator")
}

export const removeClient = () => {
    sessionStorage.removeItem("Client")
}