import "./QRReader.css"
import { Html5QrcodeScanner } from "html5-qrcode";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import { getOperator } from "../utils/common";

function QRReader(props) {
    const [nomer, setNomer] = useState('')
    const [name, setNama] = useState('')
    const [seat, setKursi] = useState('')
    const [guest, setJumlah] = useState('')
    const [actualguest, setJumlahAsli] = useState('')
    const [give, setGive] = useState('')
    const [success, setSuccess] = useState(false)
    const [checked, setChecked] = useState(false);
    const [timestamp, setTimestamp] = useState(new Date().toLocaleString());

    const handleChange = nextChecked => {
      setChecked(nextChecked);
    };

    const refreshPage = () => {
        window.location.reload();
    };

    useEffect(() => {
      const scanner = new Html5QrcodeScanner('reader',{
        qrbox:{
          width: 250,
          height: 250
        },
        fps: 5,
      })
    
      scanner.render(success, error);
      
      function getnomer(result){
        const commaIndex = result.indexOf(',');
        const getname = result.substring(0, commaIndex);
        return getname
      }

      function getname(result){
        const substrings = result.split(',');
        const getseat = substrings[1];
        return getseat
      }

      function getseat(result){
        const substrings = result.split(',');
        const getseat = substrings[2];
        return getseat
      }

      function getguest(result){
        const substrings = result.split(',');
        const getguest = substrings[3];
        return getguest
      }

      function success(result){
        scanner.clear();
        setSuccess(true)
        setNomer(getnomer(result))
        setNama(getname(result))
        setKursi(getseat(result))
        setJumlah(getguest(result))
      }
    
      function error(err){
        console.warn(err);
      }
      const updateTimestamp = () => {
        setTimestamp(new Date().toLocaleString());
      };
  
      const intervalId = setInterval(updateTimestamp, 1000);
      return () => {
        clearInterval(intervalId);
      };
    },[])

    const printDiv = () => {
      var data = new FormData();
      data.append('Time', timestamp)
      data.append('Nomor', nomer)
      data.append('Name', name);
      data.append('Seat', seat);
      data.append('Guest', guest);
      data.append('ActualGuest', actualguest);
      data.append('Give', checked);
      data.append('Jumlah', give)
      data.append('Operator', getOperator())
      fetch(
        "https://script.google.com/macros/s/AKfycbylzdK9McIeXSkJuMgP4dDA6QJ3Lj4NxDRzQjQfaoXwDTGHDMT1Zb6TeUyNm45E_rnCGg/exec",
        {
          method: "POST",
          body: data
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
      const printContents = document.getElementById("printableDiv").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      document.querySelector("input[type='submit']").addEventListener("click", printDiv);
  };

    return (
        <div className="loginForm">
            <div className="form-container">
                <div className="form-inner">
                    <Link to='/input' style={{ textDecoration: 'none' }}>
                        <div className='back-home'>
                            <i class="fas fa-long-arrow-alt-left" />
                            &ensp;Back to homepage
                        </div>
                    </Link>
                    <h2>Scan Your QR Code</h2>
                    {success ? 
                    <>
                        <div id="printableDiv">
                            <div className="form-group">
                                <label htmlFor="nama">Nomor Angpao: </label>
                                {nomer}
                            </div>
                            <div className="form-group">
                                <label htmlFor="nama">Nama: </label>
                                {name}
                            </div>
                            <div className="form-group">
                                <label htmlFor="seat">Seat: </label>
                                {seat}
                            </div>
                            <div className="form-group">
                                <label htmlFor="guest">Guest: </label>
                                {guest} 
                            </div>
                            <div className="form-group">
                                <label htmlFor="guest">Actual Guest: </label>
                                <input type="text" name="guest" id="guest" onChange={e => setJumlahAsli(e.target.value)} value={actualguest}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="guest">Give: </label>
                                {checked ? 
                                <input type="text" name="jumlah" id="jumlah" onChange={e => setGive(e.target.value)} value={give}/>
                                :
                                <></>
                                }
                                <Switch
                                  onChange={handleChange}
                                  checked={checked}
                                  className="react-switch"
                                />
                            </div>
                        </div>
                        <input type="submit" value="Print" onClick={printDiv}/>
                        <Link to="/qrreader">
                            <input type="submit" value="Scan Ulang" onClick={refreshPage}/>
                        </Link>
                    </>                    
                    :
                    <div id='reader'></div>
                    }
                </div>
            </div>
        </div>
    )
}

export default QRReader
